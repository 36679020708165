.nav-container {
  top: 0;
  position: sticky;
  z-index: 1;
}

nav {
  background-color: var(--bg-color);
}

.nav-content {
  margin: auto;
  padding: 0px 10px;
  max-width: var(--width);
}

.nav-item {
  margin: 0 1rem;
  font-weight: 600;
  color: black !important;
  cursor: pointer;
}

.nav-item:hover {
  background-color: var(--bg-color) !important;
}

.wattpad-logo {
  max-width: 10rem;
  vertical-align: middle;
}

ul {
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
}

.dropdown-container {
  &-me,
  &-browse {
    z-index: 1;
    background-color: #fff;
    margin: -0.5rem 1rem;
    position: absolute;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: 0pt 1pt 5pt #000;
  }

  &-me {
    display: flex;
    transform: translateX(-100%);

    .dropdown-item-me {
      line-height: 3rem;
      padding: 0 1rem;
      margin: 0.5rem;
      width: 10rem;
      color: black;
      border-radius: 2rem;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }

    hr {
      margin: 0 1rem;
    }
  }

  &-browse {
    display: grid;
    transform: translateX(-50%);
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 80vh;
    overflow: auto;

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }

    .dropdown-item-browse-header {
      grid-column: 1/4;
      line-height: 2rem;
      padding: 0 1rem;
      margin: 0.5rem;
      width: 10rem;
      color: black;
      border-radius: 2rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dropdown-item-browse {
      line-height: 2rem;
      padding: 0 1rem;
      margin: 0.5rem;
      width: 10rem;
      color: black;
      border-radius: 2rem;
      cursor: pointer;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .nav-item {
    margin: 0 0.2rem;
  }
  .wattpad-logo {
    max-width: 5rem;
  }
}
