.story-card-container {
  margin-right: 2rem;
}

.card {
  width: 11rem;
  border-radius: 0.5rem !important;
}

.card-image img {
  border-radius: 0.5rem !important;
}

.card-tags {
  text-align: left;
  display: flex;
  align-items: center;
}

.material-icons {
  font-size: 1.3rem;
  margin-right: 0.2rem;
}

.chip {
  margin: 0.5rem 0;
  padding: 0 1rem;
  font-size: 1rem;
}